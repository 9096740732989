<template>
    <div>
        <div class="popup-image">
           <div class="popup-image-in" :style="{ backgroundColor: data.background_color }">
                <img :src="cmsAsset(data.image)" width="100%" />
                <a class="content-btn" target="_blank" :href="data.button_link"></a>
                <div class="header-popup">
                    <a href="#" @click="close">
                        <!-- <span class="close_button"></span> -->
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Popup",
        components: {},
        data() {
            return {
            }
        },
        props: {
            data: {
                type: Object,
                require: true
            }
        },
        methods: {
            close(){
                this.$emit('popupClose');
            }
        },
    }

</script>
