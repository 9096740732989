<template>
   <div class="page_section" v-if="homePageLoaded">
      <PopupPdf v-if="showPopup && popupData" :data="popupData" @popupClose="handlePopup"></PopupPdf>
      <PopupImage v-if="showPopupImg && popupDataImg" :data="popupDataImg" @popupClose="handlePopupImg"></PopupImage>
      <MobileTitle :title="page.homepage.title"/>
     
      <div v-for="(element, index) in page.homepage.content_elements" :key="'fl-' + index">
         <template v-if="element._group !== 'image_and_content'">
            <ContentElements :content="element" />
         </template>
      </div>
<!--      <ContentElements  v-for="(element, index) in page.homepage.content_elements" :content="element" :key="index"/>-->

      <div class="info" v-if="pageLoaded">
         <ContentElements v-for="(element, index) in trafficNewsPage.content_elements" :content="element" :key="'thl-' + index"/>
      </div>
      <div :key="index" v-for="(element, index) in page.homepage.content_elements">
         <template v-if="element._group === 'image_and_content'">
            <ContentElements :content="element" :key="'sl-' + index"/>
         </template>
      </div>
      <Footer/>
      <Popup
         v-if="dramaticEvent.event"
         :additionalClass="'dramatic-event-popup'"
         :visible="dramaticEvent && dramaticEvent.event !== null && showDramaticEventPopup === true"
         @popupClose="showDramaticEventPopup = false"
      >
         <template slot="header"><i class="icon ab-icon-warning"></i></template>
         <h1 class="font-weight-bold">{{ dramaticEvent.event.title }}</h1>
         <p class="h3 font-weight-light" v-html="dramaticEvent.event.content"></p>
      </Popup>
   </div>
</template>

<script>
import {mapState} from 'vuex';
import {config} from "../config";
import Footer from "./_common/Footer";
import MobileTitle from "./_common/MobileTitle";
import ContentElements from "./_content_elements/ContentElements";
import Popup from "@/components/_common/Popup";
import PopupPdf from "@/components/_common/PopupPdf";
import PopupImage from "@/components/_common/PopupImage";


export default {
   name: 'Home',
   components: {
      Popup,
      Footer,
      MobileTitle,
      ContentElements,
      PopupPdf,
      PopupImage
   },
   metaInfo() {
      return {
         title: this.homePageLoaded ? (this.page.homepage.meta.title !== '' ? this.page.homepage.meta.title : this.page.homepage.title) : this.settings.all.site_name,
         titleTemplate: '%s | ' + this.settings.all.site_name,
         meta: [{
            name: 'description',
            content: this.page.homepage?.meta?.description,
         }, {
            name: 'keywords',
            content: this.page.homepage?.meta?.keywords,
         }]
      }
   },
   data() {
      return {
         homePageLoaded: false,
         pageLoaded: false,
         showDramaticEventPopup: false,
         trafficNewsPage: null,
         show: true,
         imageContent: {
            title: '9-Euro-Ticket: Umsetzung noch offen',
            content: 'Bund und Länder haben sich darauf verständigt, zur Entlastung der Bürgerinnen und Bürger angesichts der stark gestiegenen Energiepreise das sogenannte 9-für-90-Ticket einzuführen: Für 9 Euro im Monat sollen alle drei Monate lang mit Bus und Bahn fahren können. Dieser überraschende Beschluss muss nun von den Verkehrsverbünden und den Unternehmen umgesetzt werden. Das ist keine leichte Aufgabe, daher sind sowohl Zeitplan als auch die konkrete Umsetzung noch offen. Wir bitten euch noch um Geduld, denn am Ende muss es ein einheitliches und einfaches Angebot für alle Fahrgäste geben, egal wo sie mit diesem Ticket unterwegs sind.',
            buttonUrl: '#',
            buttonTitle: 'Weitere Informationen zur Umsetzung',
            image: 'https://obj.abellio.de/public/Service_Juli_2022.jpg'
         },
         textBlock: {
            title: '9-Euro-Ticket: Umsetzung noch offen',
            content: 'Bund und Länder haben sich darauf verständigt, zur Entlastung der Bürgerinnen und Bürger angesichts der stark gestiegenen Energiepreise das sogenannte 9-für-90-Ticket einzuführen: Für 9 Euro im Monat sollen alle drei Monate lang mit Bus und Bahn fahren können. Dieser überraschende Beschluss muss nun von den Verkehrsverbünden und den Unternehmen umgesetzt werden. Das ist keine leichte Aufgabe, daher sind sowohl Zeitplan als auch die konkrete Umsetzung noch offen. Wir bitten euch noch um Geduld, denn am Ende muss es ein einheitliches und einfaches Angebot für alle Fahrgäste geben, egal wo sie mit diesem Ticket unterwegs sind.',
         },
         showPopup: false,
         popupData: null,
         showPopupImg: false,
         popupDataImg: null
      }
   },
   computed: {
      ...mapState([
         'page',
         'region',
         'locales',
         'settings',
         'dramaticEvent'
      ])
   },
   created() {
      this.$store.dispatch('dramaticEvent/loadAll').then((item) => {
         if (item)
            this.showDramaticEventPopup = true
      })

      this.$store.dispatch('page/getHomepage').then(() => {
         !localStorage.getItem('abPopups') && localStorage.setItem('abPopups', '[]')
         !localStorage.getItem('imgPopups') && localStorage.setItem('imgPopups', '[]')
         this.homePageLoaded = true;
         this.checkPopups()
         this.checkImagePopups()
      });
      if (localStorage.getItem('ttableOpen') !== 'done') {
         this.$store.commit('globalPopup/setTimetableOpen', true);
         localStorage.setItem('ttableOpen', 'done');
      }

      this.$store.dispatch('page/getByID', config.trafficNewsIds[this.region.currentRegion.id]).then((data) => {
         this.trafficNewsPage = data;
         this.pageLoaded = true;
      })
   },
   watch: {
      "region.currentRegion": function () {
         this.$store.commit('loading/setFullPageLoaderVisible', true)
         this.$store.dispatch('page/getByID', config.trafficNewsIds[this.region.currentRegion.id]).then((data) => {
            this.pageLoaded = true;
            this.trafficNewsPage = data;
            this.$store.commit('loading/setFullPageLoaderVisible', false)
         })
      }
   },
   methods: {
      handlePopup () {
         let localStr = JSON.parse(localStorage.getItem('abPopups'))
         localStr.push(this.popupData._key) // change _group to id
         localStorage.setItem('abPopups', JSON.stringify(localStr))
         this.showPopup = false
      },
      handlePopupImg () {
         let localStr = JSON.parse(localStorage.getItem('imgPopups'))
         localStr.push(this.popupDataImg._key) // change _group to id
         localStorage.setItem('imgPopups', JSON.stringify(localStr))
         this.showPopupImg = false
      },
      checkPopups () {
         this.popupData = this.page.homepage.content_elements.find(ele => ele._group === 'popup_element') 
         if (this.popupData) {
            const localStr = JSON.parse(localStorage.getItem('abPopups'))
            this.showPopup = !localStr.includes(this.popupData._key)
         } else {
            this.showPopup = this.popupData
            // this.checkImagePopups()
         }
      },
      checkImagePopups () {
         this.popupDataImg = this.page.homepage.content_elements.find(ele => ele._group === 'popup_element_image') 
         if (this.popupDataImg) {
            const localStr = JSON.parse(localStorage.getItem('imgPopups'))
            this.showPopupImg = !localStr.includes(this.popupDataImg._key)
         } else {
            this.showPopupImg = this.popupDataImg
         }
      },
   }
}
</script>


<style scoped lang="scss">
.info .banner,
.info .accordion_element {
   display: none;
}
</style>
