<template>
    <div>
        <div class="popup-pdf">
           <div class="popup-pdf-in"  :style="{ backgroundColor: data.background_color }">
                 <div class="header-popup">
                    <a href="#" @click="close" :style="{ backgroundColor: data.background_color }"><span class="close_button"></span></a>
                </div>
                <div class="content-popup">
                    <h1 v-text="data.title"></h1>
                    <h4 v-html="data.content"></h4>
                    <h4><strong v-html="data.content_2"></strong></h4>
                    <img :src="data.image" width="100%" />
                </div>
                <div class="footer-popup">
                    <a target="_blank"  :href="data.button_link">{{ data.button_title }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Popup",
        components: {},
        data() {
            return {
            }
        },
        props: {
            data: {
                type: Object,
                require: true
            }
        },
        methods: {
            close(){
                this.$emit('popupClose');
            }
        }
    }
</script>
